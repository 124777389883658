<template>
  <b-card>

    <!-- search input -->
    <b-row class="mb-1">
      <b-col lg="4">
        <b-form-input
          v-model.lazy="searchTerm"
          placeholder="Search..."
          type="text"
          class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          @keyup="onSearch"
        />
      </b-col>
      <b-col
        lg="8"
        class="d-flex justify-content-end align-items-center"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="flex-shrink-0"
          variant="primary"
          @click="showModal"
        >
          Create Company
        </b-button>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading.sync="isLoading"
      :rows="rows"
      :sort-options="{
        enabled: false,
        multipleColumns: true,
        initialSortBy: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
          { field: 'created_at', type: 'desc' },
        ],
      }"
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- head -->
        <span class="font-weight-bold" v-if="props.column.field === 'format_name'">
          {{
            props?.row?.name
          }}
        </span>

        <span v-if="props.column.field === 'format_address'">
          <b-button v-if="props.row?.address"
              v-b-tooltip.hover
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              :title='props.row?.address'
          >
            <feather-icon class="btn-icon" icon="InfoIcon" />
          </b-button>
        </span>

        <span v-if="props.column.field === 'format_status'">
            <b-badge :variant="props.row.status === 'active' ? 'light-success' : 'light-danger'">
              {{ props.row.status === 'active' ? "Active" : "Inactive" }}
            </b-badge>
          </span>

        <span v-if="props.column.field === 'action'">

          <span @click="onLogin(props.row)">
            <feather-icon
              v-b-tooltip.hover
              icon="KeyIcon"
              class="mr-50 custom-icon cursor-pointer"
              title="Login"
              size="16"
            />
          </span>

          <span @click="onShow(props.row)">
            <feather-icon
              v-b-tooltip.hover
              icon="Edit2Icon"
              class="mr-50 custom-icon cursor-pointer"
              title="Edit"
              size="16"
            />
          </span>

<!--          <span @click="onDelete(props.row)">-->
<!--            <feather-icon-->
<!--              v-b-tooltip.hover-->
<!--              icon="TrashIcon"-->
<!--              class="mr-50 custom-icon cursor-pointer"-->
<!--              title="Delete"-->
<!--              size="16"-->
<!--            />-->
<!--          </span>-->

        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50', '100', '500']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="modal-company-form"
      centered
      :title="modelType === 'editModel' ? 'Edit Company' : 'Create Company'"
      hide-footer
      no-close-on-backdrop
      @hidden="hiddenModal"
    >
      <validation-observer ref="companyValidation">
        <b-form @submit.prevent="validationForm">
          <!-- name -->
          <b-form-group
            label="Name"
            label-for="name"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              vid="name"
              rules="required|max:255"
            >
              <b-form-input
                id="name"
                v-model="name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Company"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- email -->
          <b-form-group
              label="Email "
              label-for="email"
              class="required-label"
          >
            <validation-provider
                #default="{ errors }"
                type="email"
                name="email"
                :rules="`${modelType === 'editModel' ? '' : 'required'}|email`"
                vid="email"
            >
              <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                  placeholder="john@example.com"
                  class="custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Status"
            label-for="status"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="status"
              vid="status"
              rules="required"
            >
              <v-select
                v-model="status"
                :options="statusOption"
                :reduce="(item) => item.value"
                label="name"
                placeholder="Select Status"
                class="mb-1 custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Address"
            label-for="address"
          >
            <validation-provider
              #default="{ errors }"
              name="address"
              vid="address"
            >
              <b-form-textarea
                id="required-remarks"
                v-model="address"
                :state="errors.length > 0 ? false : null"
                placeholder="Address"
                rows="2"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isCompanyFormSubmitLoading">
            <b-button
              class="float-right"
              variant="primary"
              disabled
            >
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="float-right"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BLink, VBTooltip, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import UserCard from '@/layouts/components/UserCard.vue'
import store from '@/store'
import { DASHBOARD_ACCESS } from '@/helpers/permissionsConstant'
import { EventBus } from '@/libs/event-bus'

export default {
  name: 'CompanyView',
  components: {
    BFormTextarea,
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BLink,
    UserCard,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      modelType: '',
      companyId: '',
      name: '',
      email: '',
      contact_no: '',
      address: '',
      website: '',
      status: 'active',
      statusOption: [
        { name: 'Active', value: 'active' },
        { name: 'Inactive', value: 'inactive' },
      ],
      pageLength: 10,
      columns: [
        {
          label: 'Name',
          field: 'format_name',
        },
        {
          label: 'email',
          field: 'email',
        },
        {
          label: 'address',
          field: 'format_address',
        },
        {
          label: 'status',
          field: 'format_status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      delayTimer: null,
      isLoading: false,
      isCompanyFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  methods: {
    async onLogin(value) {
      try {
        this.isLoading = true
        // for login
        const resLogin = await this.$api.post('/api/verify-login', {
          company_id: value?.id,
        })

        const data = resLogin?.data

        await this.$store.dispatch('authModule/setIsAuthenticated', {
          isAuthenticated: true,
          token: data?.access_token,
        })

        const settings = await this.$api.get('/api/settings')
        store.commit("app/setGlobalDateFormat", settings?.data?.data?.date_format)

        // get user
        const resUser = await this.$api.post(
          '/api/user?include=permissions,designation,roles,department',
        )

        const { permissions, ...user } = resUser?.data?.data
        const mapPermissions = permissions?.data.map(item => item.name)

        await this.$store.dispatch('userModule/setUser', {
          user,
        })

        await this.$store.dispatch('userModule/setPermissions', {
          permissions: mapPermissions,
        })

        this.isLoading = false

        let routeName = 'dashboard'

        if (mapPermissions.includes(DASHBOARD_ACCESS)) {
          routeName = 'admin-dashboard'
        }

        EventBus.$emit('company-login', true)
        this.$router.replace({ name: routeName })
      } catch (error) {
        this.isLoading = false

        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              variant: 'danger',
              text: error?.response?.data?.message,
            },
          })
        }
      }
    },
    showModal() {
      this.$bvModal.show('modal-company-form')
    },
    hiddenModal() {
      this.$bvModal.hide('modal-company-form')
      this.resetModal()
    },
    resetModal() {
      this.modelType = ''
      this.name = ''
      this.email = ''
      this.contact_no = ''
      this.address = ''
      this.website = ''
      this.status = 'active'
    },
    onShowPage(id) {
      this.$router.push({
        name: 'admin-company-details',
        params: { id },
      })
    },
    async onShow(value) {
      this.modelType = 'editModel'
      this.name = value.name
      this.email = value.email
      this.address = value.address
      this.status = value.status
      this.companyId = value?.id ? +value?.id : ''

      this.showModal()
    },
    async onDelete(row) {
      const { id, name } = row
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Delete ${name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/companies/${id}`)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Company Deleted Successfully',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async getCompanies(params) {
      return await this.$api.get('api/companies', {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      })
    },

    async loadItems() {
      try {
        const [companies] = await Promise.all([
          this.getCompanies({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ])

        const data = companies?.data?.data

        const meta = companies?.data?.meta

        this.totalRecords = meta?.pagination?.total
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async validationForm() {
      this.$refs.companyValidation.validate().then(async success => {
        if (success) {
          try {
            if (this.modelType === 'editModel') {
              this.isCompanyFormSubmitLoading = true
              await this.$api.put(`/api/companies/${this.companyId}`, {
                name: this.name,
                email: this.email,
                address: this.address,
                status: this.status,
              })
              this.isCompanyFormSubmitLoading = false
              this.loadItems()
              this.hiddenModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Updated',
                },
              })
            } else {
              this.isCompanyFormSubmitLoading = true
              await this.$api.post(
                '/api/companies',

                {
                  name: this.name,
                  email: this.email,
                  address: this.address,
                  status: this.status,
                },
              )
              this.isCompanyFormSubmitLoading = false
              this.hiddenModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            }
          } catch (error) {
            this.isCompanyFormSubmitLoading = false

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message,
                },
              })
            }

            if (error?.response?.data?.errors) {
              this.$refs.companyValidation.setErrors(
                error?.response?.data?.errors,
              )
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
